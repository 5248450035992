import React from 'react';

const ActiveMetroIcon = ({ width = 10, height = 10, color, background, ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g filter="url(#filter_metroActive)">
        <circle className="bg" cx="21.5004" cy="19.5004" r="16.8636" fill={background} />
        <circle className="border" cx="21.5004" cy="19.5004" r="16.8636" stroke="#A8080E" />
      </g>
      <path
        className="fg"
        d="M30.172 27.2091H12.3447V23.8132L13.5266 23.5593V17.0212L15.4046 15.953L16.6128 16.5036V17.285L21.6064 15.1617L28.6289 18.2049V19.0984L21.5735 16.3058L16.7113 18.373V19.4611L21.6064 17.7136L28.6289 20.1963V21.3404L21.59 18.9203L16.6784 20.526V21.6371L21.5965 20.3513L28.6289 22.2405V23.4835L30.1556 23.8527L30.172 27.2091Z"
        fill="white"
      />
      <path
        className="fg"
        d="M16.6457 16.1574L21.5868 13.7802L28.629 17.074V16.1607L21.5703 12.5669L16.6457 15.1188C16.6457 15.1188 16.6457 16.2068 16.6457 16.1574Z"
        fill="white"
      />
      <path className="fg" d="M16.6457 14.0241L21.5933 11.2084L28.629 15.0956V13.5262L21.5736 9.38184L16.6457 12.4151V14.0241Z" fill={color} />
      <defs>
        <filter
          id="filter_metroActive"
          x="0.603385"
          y="0.0167187"
          width="41.7939"
          height="41.7939"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="1.41333" />
          <feGaussianBlur stdDeviation="1.76667" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default ActiveMetroIcon;
