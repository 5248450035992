import React, { useState } from 'react';
import './MapKey.scss';
import cn from 'classnames';
import ActiveMetroIcon from '../icons/ActiveMetro';

const MapKey = () => {
  const [isMapKeyOpen, setIsMapKeyOpen] = useState(false);
  const toggleMenu = () => {
    setIsMapKeyOpen((prev) => !prev);
  };

  return (
    <div className="map-key-container">
      <div onClick={toggleMenu} className={'title'}>
        {isMapKeyOpen ? 'HIDE' : 'SHOW'} MAP KEY
      </div>
      <div className={cn('content', { visible: isMapKeyOpen })}>
        <div className="item">
          <div className="icon-section">
            <hr className="yellow" />
          </div>
          <span>Cloud Service Provider Backbone</span>
        </div>
        <div className="item">
          <div className="icon-section">
            <hr className="red-solid" />
            <hr className="red-dotted" />
          </div>
          <span>Equinix Fabric Backbone</span>
        </div>
        <div className="item">
          <div className="icon-section">
            <ActiveMetroIcon width={20} height={20} color={'white'} background={'#ED1C24'} />
          </div>
          <span>Equinix Metro with Deployment</span>
        </div>
        <div className="item">
          <div className="icon-section">
            <span>18ms</span>
          </div>
          <span>Latency: Average Monthly Round-Trip Time</span>
        </div>
      </div>
    </div>
  );
};

export default MapKey;
